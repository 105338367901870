

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
	font-family: Raleway, sans-serif;
}

.box {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	/* border: 3px solid  red; */
}

.screen {		
   justify-content: center;
   align-items: center;
   height: 100px;
   background-color: white;
   color: white;
   display: flex;
}

.login__field {
	padding: 20px 0px;	
}

.login__icon {
	top: 7px;
	left: 5px;
	right: 3px;
	color: white;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background-color: white;
	padding: 10px;
	padding-left: 25px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input::placeholder{
	color: black;
}

.forgot__password{
  font-weight: 700;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #9e6767;
}


.education table{
	box-shadow: 5px 5px 10px grey;
}
.education th{
	/* border: 1px solid rgb(207, 205, 205); */
	/* background-color: rgb(233, 224, 227); */
	padding: 10px;
	text-align: center;
	width: 300px;
	color:black;
}

.education td{
	/* border: 1px solid rgb(168, 165, 165); */
	padding: 10px;
	text-align: center;
}
.education tr{
	border: 1px solid rgb(207, 205, 205);
}

.education tr:hover {
	box-shadow: 5px 5px 10px grey;
  }

  .text-center {
	text-align: center;
  }
  