*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.searchHeader{
  padding:20px 30px 0px 30px;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
}
.searchHeader input{
  width: 300px;
  padding: 10px;
  border: 1px solid;
  border-radius: 50px;
  text-align: center;
}
.member-list{
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40px;
}
.member-box{
  border: none;
  padding: 10px;
  margin: 10px 15px;
  box-shadow: 2px 2px 5px rgb(110, 109, 109);
  /* background-color: rgb(233, 224, 227); */
  border-radius: 20px;
}
.member-box p,.member-box1 p{
  font-size: 12px;
  line-height: normal;
}
.member-box1 td{
  font-size: 12px;
  border: 1px solid rgb(189, 185, 185);
}
.small-photo{
    justify-content: center;
    text-align: center;
    display: flex;
    width: 100%;
    height: 130px;
    /* border: 1px solid; */
    border-radius: 10px 10px;
}
.operation-buttons{
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* for button 1 and 2 */
.button1, .button2{
  padding: 8px;
  margin: 10px 20px;
  width: 100px;
  font-weight: bold;
  border: 1px solid;
  border-radius: 10px;
}
.button1:hover{
  background-color: green;
  color: white;
}

.button2:hover{
  background-color: red;
  color: white;
}

.large-photo-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.large-photo{
  height: 350px;
  width: 100%;
}

.modal-content {
  margin: 3% auto;
  /* border: 1px solid #888; */
  width: 100%;
  background-color: #fff;
  position: relative;
}
.modal-content1 {
  margin: 3% auto;
  width: 60%;
  background-color: #fff;
  position: relative;
  padding:0px 15px 5px 15px;
}

.modal-contentPhotoApp {
  margin: 3% auto;
  padding: 20px;
  border: 1px solid #888;
  /* width: 50%; */
  background-color: #fff;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.profile-details{
  display: flex;
  flex-direction: row;
}
.profile-details p{
  font-size: 14px;
}
.profile-details .para{
  font-weight: bold;
}
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  margin-top: -20px;
}

.close:hover,
.close:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
}
.close1 {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  margin-top: -20px;
}

.close1:hover,
.close1:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
}


p{
  line-height: 10px;
  font-size: 14px;
}


.viewButtons1, .viewButtons2{
  border:1px solid rgb(241, 84, 241);
  width: 90px;
  color: white;
  border-radius: 10px;
  margin: 0px 5px;
  font-size: 12px;
  padding: 5px;
  text-decoration: none;
  background-color: rgb(241, 84, 241);
}
.viewButtons1:hover{
  background-color: #196F3D;
  color: white;
  border: 1px solid #196F3D;
}
.viewButtons2:hover{
  background-color: #fc0c0c;
  color: white;
  border: 1px solid #f30d0d;
}

.profile-buttons{
  text-align: center;
}

.filter-buttons{
  background-color: #f1eded;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  margin: 0px 0px 10px 0px;
  box-shadow: 3px 3px 8px rgb(163, 160, 160);
}

.filter-buttons input{
  width: 300px;
  border: 1px solid;
  border-radius: 50px;
  padding: 10px;
  text-align: center;
}

.filterButtonsBack{
  padding: 5px 5px 5px 10px;
  display: flex;
  font-weight: 550;
}

.filterButtons{
  padding: 5px;
  text-decoration: none;
  color: #000;
  font-weight: 550;
  border-radius: 10px;
  text-align: center;
  display: flex;
}
.photoApprove{
  box-shadow:2px 2px 5px #888;
}

.photoApprove th{
  border: 1px solid rgb(189, 185, 185);
  background-color: rgb(102, 5, 5);
  color: white;
}
.photoApprove tr{
  background-color: rgb(241, 237, 237);
  color: black;
}
.photoApprove tr:hover{
  background-color: rgb(253, 253, 253);
}

.editProfileTitle{
  background-color: #f1eded;
  padding: 10px;
  text-align: start;
  justify-content: space-evenly;
  margin: 0px 0px 10px 0px;
  box-shadow: 3px 3px 8px rgb(163, 160, 160);
}



/* ===================================================================================== */

/* ====================DATA TABLE CSS===================== */

.custom-datatable .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-datatable .dataTables_paginate .pagination {
  color: #007bff; /* Example text color */
  position: relative; /* Ensure proper positioning */
  z-index: 0; /* (prevent from overlapping) */
}

.custom-datatable .dataTables_info {
  display: none;
}

.custom-datatable thead:last-child {
  display: none;
}

.custom-datatable .dataTables_length label {
  font-size: 12px;
}

.custom-datatable .dataTables_length select {
  width: 50px;
}



 .custom-datatable thead th {
  background: #660505;
  background: -webkit-linear-gradient(to right, #660505, #660505); 
  background: linear-gradient(to right, #660505, #660505);
 color: #fff;
}


/* ======payment approval css =========== */
.approveBtn{
  padding: 8px;
  margin: 10px 20px;
  width: 100px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: green;
  color: white;
}

.rejectBtn{
  padding: 8px;
  margin: 10px 20px;
  width: 100px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: red;
  color: white;
}
