
.custom-datatable .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-datatable {
  }
  .custom-datatable .pagination a {
    
  }
  
  .custom-datatable .dataTables_info {
    display: none;
  }
  
  .custom-datatable thead:last-child {
    display: none;
  }
  
  .custom-datatable .dataTables_length label {
    font-size: 12px;
  }
  
  .custom-datatable .dataTables_length select {
    width: 50px;
  }
  
   .custom-datatable thead th {
    background: #660505;
    background: -webkit-linear-gradient(to right, #660505, #660505); 
    background: linear-gradient(to right, #660505, #660505);
   color: #fff;
  }
  