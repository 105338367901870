*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* border: 1px solid greenyellow; */
    font-variant-numeric: lining-nums;
}
.EntryBtn:hover{
    transform:rotate(20deg);
}
