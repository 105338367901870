.dropdown-item:hover {
	background-color: #e9e0e3; 
	color: #000; 
  }

  .dropdown-toggle::after {
	color: #ffff;
  }
  .dropdown-toggle::before {
	color: #ffff;
  }